import styled from "styled-components";
import { Heading } from "./utils";

export default function LinkedInPostEmbed() {
    return (
        <Wrapper>
            <Heading title="LinkedIn Featured Posts" />
            <Cards>
                <iframe style={{ height: '21.5rem', width: '18rem' }} src="https://www.linkedin.com/embed/feed/update/urn:li:share:7164561239522656257" height="400" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                <iframe style={{ height: '21.5rem', width: '18rem' }} src="https://www.linkedin.com/embed/feed/update/urn:li:share:7164573519165468672" height="1198" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                <iframe style={{ height: '21.5rem', width: '18rem' }} src="https://www.linkedin.com/embed/feed/update/urn:li:share:7164580131628331008" height="988" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            </Cards>
        </Wrapper>
    )
}


const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 1rem;
`;

