import sahasra from "./Images/sahasra_seva_samithi.png";

const data = [
  {
    skills: [
      {
        id: 0,
        name: "HTML5",
        icon: ["fab", "html5"],
        percentFill: 90,
        color: "lightblue",
      },
      {
        id: 1,
        name: "CSS3",
        icon: ["fab", "css3"],
        percentFill: 90,
        color: "orange",
      },
      {
        id: 2,
        name: "JavaScript",
        icon: ["fab", "js-square"],
        percentFill: 80,
        color: "#FFFF00",
      },
      {
        id: 3,
        name: "ReactJS",
        icon: ["fab", "react"],
        percentFill: 80,
        color: "lightblue",
      },
      {
        id: 4,
        name: "AngularJS",
        icon: ["fab", "angular"],
        percentFill: 80,
        color: "#B52E31",
      },
      {
        id: 5,
        name: "VueJS",
        icon: ["fab", "vuejs"],
        percentFill: 80,
        color: "#42b983",
      },
      {
        id: 6,
        name: "NodeJS",
        icon: ["fab", "node-js"],
        percentFill: 80,
        color: "green",
      },
      {
        id: 7,
        name: "React Native",
        icon: ["fab", "react"],
        percentFill: 80,
        color: "lightblue",
      },
    ],
  },
  {
    projects: [
      {
        id: 0,
        name: "Medmate | Admin Portal | Web App",
        desc: "Medmate is the easy way to get your scripts filled and shop your favourite pharmacy items.",
        website: "https://medmate.com.au/",
        technologies: ["AngularJS", "VueJS", "Typescript", "Material UI", "PHP",],
        photo: "https://medmate.com.au/wp-content/uploads/2021/02/medmate-blue-logo.png",
      },

      {
        id: 1,
        name: "ScrapQ | Agent & Consumer Apps | Website",
        desc: "ScrapQ is a unique service provider in collection of recyclables, enabling recycling services in Hyderabad.",
        website: "https://scrapq.com/",
        technologies: ["Ionic FW", "AngularJS", "Typescript", "Material UI"],
        photo: "https://scrapq.com/assets/scrapq.png",
      },
      {
        id: 2,
        name: "Click2Clean | Vendor & Consumer Apps | Website",
        desc: "Click2Clean is a leading aggregator platform in India that specializes in providing waste management and cleaning services.",
        website: "https://www.click2clean.in/",
        technologies: ["Ionic FW", "AngularJS", "Typescript", "Material UI"],
        photo: "https://www.click2clean.in/assets/click2clean-logo.png",
      },
      {
        id: 3,
        name: "Next Skills 360 Dashboard",
        desc: "NS360 is a web application that displays school data in dashboards at different project levels and year-wise.",
        website: "https://nextskills360-dashboard.web.app/",
        technologies: ["ReactJS", "Styled-Components", "Firebase"],
        photo: "https://nextskills360-dashboard.web.app/static/media/nextskills360_logo.c2c0ff33dd58ba43d08a.png",
      },
      {
        id: 4,
        name: "Touch UI Mobile App",
        desc: "Touch – the social media app that helps you connect with friends and family, share your passions, and discover new interests.",
        website: "https://play.google.com/store/apps/details?id=com.touchhapp",
        technologies: ["React Native", "Firebase"],
        photo: "https://play-lh.googleusercontent.com/mKCk5fUhpGMTs-GFpkJ38R_v7H1jLerDeic7Y3ak3vSfyHvlVEBMocxaMk_r0TvYP6U=w240-h480-rw",
      },
      {
        id: 5,
        name: "Sahasra Seva Samithi",
        desc: "Sahasra Seva Samithi is a charitable organisation. This progressive web application is built using React.",
        website: "https://sahasrasevasamithi.org",
        technologies: ["ReactJS", "React-Bootstrap", "Bootstrap-5"],
        photo: sahasra,
      },
    ],
  },
];

export default data;
