import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import profile from "../Images/profile-image.jpg";
import { useEffect } from "react";
export default function About() {


  useEffect(() => {
    const shareButton = document.getElementById("shareButton");
    if (shareButton) {
      shareButton.addEventListener("click", () => {
        if (navigator.share) {
          navigator.share({
            title: "Check out my profile!",
            text: "Discover more about me on my profile.",
            url: window.location.href,
          })
            .then(() => console.log("Successfully shared"))
            .catch((error) => console.error("Sharing failed:", error));
        } else {
          // Fallback for browsers that don't support the Web Share API
          alert("Your browser doesn't support sharing.");
        }
      })
    }
  }, [])

  return (
    <Wrapper id="about">
      <AboutLeft>
        <h2>Hello, I'm Hanuman</h2>
        <h3>Software Developer | Mobile & Web App Pro 🚀</h3>
        <p>
          💡 Love turning ideas into amazing mobile and web apps! Let's work together to create something awesome 📱
        </p>
        <ButtonsWrapper>
          <Button href="https://wa.me/message/RFOOXWE5XV6QN1" target="_blank" rel="noopener noreferrer" aria-label="Chat Now">Chat Now</Button>
          <Button href="https://calendly.com/hanuman_singh/30min" target="_blank" rel="noopener noreferrer" aria-label="Book a Call">Book a Call</Button>
        </ButtonsWrapper>
      </AboutLeft>
      <AboutRight>
        <ProfileImage src={profile} alt="profile" width="200" height="200" />
        <ul>
          <li>
            <a href="mailto:contact@hanumansingh.dev" aria-label="Email Hanuman Singh" rel="noopener noreferrer" alt="Email">
              <FontAwesomeIcon icon={["far", "envelope"]} size="2x" />
            </a>
          </li>
          <li>
            <a href="https://github.com/Hanuman226" aria-label="Visit Hanuman's GitHub profile" rel="noopener noreferrer" alt="GitHub">
              <FontAwesomeIcon icon={["fab", "github"]} size="2x" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/hanuman-singh/" aria-label="Visit Hanuman's LinkedIn profile" rel="noopener noreferrer" alt="LinkedIn">
              <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
            </a>
          </li>
          <li>
            <a href="https://instagram.com/hanumanu_singh_2811" aria-label="Visit Hanuman's Instagram profile" rel="noopener noreferrer" alt="Instagram">
              <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
            </a>
          </li>

          <li id="shareButton">
            <FontAwesomeIcon icon={["far", "share-square"]} size="2x" />
          </li>
        </ul>
      </AboutRight>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 6rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 1rem;
  @media (min-width: 991px) {
    /* flex-direction: row-reverse; */
    /* padding: 5rem 0 13rem 0; */
    /* margin-top: 9rem; */
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap:nowrap;
  gap:15px;
`;

const AboutLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    font-size: 2.875rem;
    line-height: 1;
    margin-bottom: 0.5rem;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 2rem;
    }
  }
  & h3 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    color: rgb(119, 119, 121);
    text-align: center;
    @media (max-width: 576px) {
      font-size: 1.2rem;
    }
  }

  & p {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
`;

const AboutRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & ul {
    display: flex;
    list-style: none;
  }
  & li {
    padding: 1rem;
    cursor:pointer;
  }
  & li a {
    color: var(--downy-color);
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  border: 0.2rem solid #212e3d;
  box-shadow: 0 0 0 0.1rem #6dcac8;
`;


const Button = styled.a`
  height: 3rem;
  padding:0 20px;
  /* width: 100%; */
  text-decoration:none;
  background-color: hsl(218 22% 18%);
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6dcac8;
  border:#6dcac8 solid 0.05rem;
  transition: all 0.5s;
  &:hover {
    background-color:  hsl(218 22% 40%);
  }
`;