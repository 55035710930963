import styled from "styled-components";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Wrapper>
      <FooterText>
        <a href="/" title="Go to the homepage" aria-label="Hanuman Singh">Hanuman Singh</a> &copy; {currentYear} |{" "}
        <span aria-label="All Rights Reserved">All Rights Reserved.</span>
      </FooterText>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  margin-top: 1rem;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(218 22% 18%);
  border-bottom: 0.2rem solid #6dcac8;
  text-align: center;
`;

const FooterText = styled.div`
  & a {
    color: #6dcac8;
    text-decoration: none;
  }
  & a:hover {
    color: hsl(179deg 47% 40%);
  }
`;
