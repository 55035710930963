import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngular,
  faCss3,
  faGithub,
  faHtml5,
  faInstagram,
  faJsSquare,
  faLinkedin,
  faNodeJs,
  faReact,
  faVuejs
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faShareSquare } from "@fortawesome/free-regular-svg-icons";

library.add(
  faGithub,
  faLinkedin,
  faEnvelope,
  faHtml5,
  faReact,
  faCss3,
  faNodeJs,
  faAngular,
  faVuejs,
  faJsSquare,
  faInstagram,
  faShareSquare
);
