import styled from "styled-components";
import data from "../data";
import ProjectCard from "./ProjectCard";
import { Heading } from "./utils";
export default function Projects() {
  return (
    <Wrapper id="projects">
      <Heading title="Featured Projects" />
      <Cards>
        {data[1].projects.map((project) => (
          <ProjectCard key={project.id + project.name} project={project} />
        ))}
      </Cards>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
`;
const Cards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 1rem;
`;
