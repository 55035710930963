// Services.js

import React from 'react';
import styled from 'styled-components';
import { Heading } from './utils';

const Services = () => {
  const servicesData = [
    {
      title: 'Web Development',
      description: 'Building responsive and user-friendly websites.',
      icon: '🌐', // You can use icons or images here,
      emailMessage: 'I am interested in your web development services. Can we discuss further?'
    },
    {
      title: 'App Development',
      description: 'Creating mobile or desktop applications.',
      icon: '📱',
      emailMessage: 'I am interested in your app development services. Can we discuss further?'
    },
    // {
    //     title: 'UI/UX Design',
    //     description: 'Crafting intuitive and attractive user interfaces.',
    //     icon: '✨',
    // },
  ];

  return (
    <ServicesContainer>
      {/* <ServicesTitle>Services</ServicesTitle> */}
      <Heading title="Services" />
      <CardsContainer>
        {servicesData.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceIcon>{service.icon}</ServiceIcon>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
            <BookServiceButton href="https://wa.me/message/RFOOXWE5XV6QN1" target="_blank" rel="noopener noreferrer" aria-label="Chat Now">Chat Now</BookServiceButton>
          </ServiceCard>
        ))}
      </CardsContainer>
    </ServicesContainer>
  );
};

const ServicesContainer = styled.div`
  width: 100%;
  margin: 0 auto
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:2rem;
  justify-content:center;
`;

const ServiceCard = styled.div`
height: 20rem;
width: 18rem;
border-radius: 0.5rem;
background-color: #2b3443;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items:center;
padding: 1rem;
  /* Style for individual service cards */
`;

const ServiceIcon = styled.div`
  font-size:4.5rem;
`;

const ServiceTitle = styled.h3`
`;

const ServiceDescription = styled.p`
`;
const BookServiceButton = styled.a`
  height: 3rem;
  padding:0 20px;
  width:100% ;
  text-decoration:none;
  background-color: hsl(218 22% 18%);
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6dcac8;
  border:#6dcac8 solid 0.05rem;
  transition: all 0.5s;
  &:hover {
    background-color:  hsl(218 22% 40%);
  }
`;

export default Services;
