import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "../data";
import styled from "styled-components";
import { Heading } from "./utils";
import mongodb from "../Images/mongodb.svg";
import ionicLogo from "../Images/ionic-logo.svg";
import flutterFlow from "../Images/flutterflow-logo.png";
import typescripLogo from "../Images/typescript_logo.png";
export default function Skills() {
  return (
    <Wrapper id="skills">
      <Heading title="Skills" />
      <Technologies>
        <ul>
          {data[0].skills.map((item) => (
            <li key={item.id}>
              <div className="skill__name">{item.name}</div>
              <div className="skill__icon">
                <FontAwesomeIcon
                  icon={item.icon}
                  size="4x"
                  color={item.color}
                />
              </div>
            </li>
          ))}
          <li>
            <div className="skill__name">Typescript</div>
            <div className="skill__icon">
              <img src={typescripLogo} alt="Typescritp logo" height="60" width="60" />
            </div>
          </li>
          <li>
            <div className="skill__name">mongoDB</div>
            <div className="skill__icon">
              <img src={mongodb} alt="mongoDB logo" height="60" width="60" />
            </div>
          </li>
          <li>
            <div className="skill__name">Ionic FW</div>
            <div className="skill__icon">
              <img src={ionicLogo} alt="Ionic logo" height="60" width="60" />
            </div>
          </li>
          <li>
            <div className="skill__name">FlutterFlow</div>
            <div className="skill__icon">
              <img src={flutterFlow} alt="FlutterFlow logo" height="60" width="60" />
            </div>
          </li>
        </ul>
      </Technologies>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding: 1rem;
`;

const Technologies = styled.div`
  width: 100%;
  margin: 0 auto;
  & ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    @media (max-width: 330px) {
      justify-content: center;
    }
  }
  & li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 8.5rem;
    width: 8.5rem;
    background-color: #2b3443;
    padding: 1rem;
    border-radius: 0.5rem;
  }
`;