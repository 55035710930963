import React, { Component } from 'react'
import { Heading } from './utils'
import styled from 'styled-components';

export default class Calendly extends Component {

    render() {
        return (
            <Wrapper>
                <Heading title="Book a Call" />
                <div class="calendly-inline-widget" data-url="https://calendly.com/hanuman_singh/30min" style={{ minWidth: "320px", height: "700px" }}></div>
            </Wrapper>
        )
    }
}

const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
`;
